<template>
  <div class="message-container">
    <div class="message">
      {{ message }}
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      notificationId: this.$route.params.timeId,
      message: null
    }
  },
  computed: {
    ...mapGetters('clients', ['client'])
  },
  async mounted() {
    const sessions = await this.$firebase
      .firestore()
      .collection('sessions')
      .where('time', '==', this.notificationId)
      .get()

    if (sessions.empty) {
      await this.sessionStart(this)
      await this.sessionProgress([this, { progress: 100 }])
    }

    this.message = 'Thank you for responding.'
  },
  methods: {
    ...mapActions('schedules', ['sessionStart', 'sessionProgress'])
  }
}
</script>

<style>
.message-container {
  font-family: var(--font-family-primary);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
body {
  background: #ffffff;
}
.message {
  color: #333333;
  font-size: 20px;
}

@media (max-width: 599px) {
  .message-container {
    height: calc(var(--vh, 1vh) * 100);
  }
}
</style>
